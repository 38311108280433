/* General Styling */
.mobile-view {
  width: 576px;
  overflow-x: hidden;
}

.section-preset {
  min-height: 100vh;
  padding: 15px 20px;
}
@media (max-width: 399px) {
  .section-preset {
    padding: 10px;
  }
}

.data-box {
  background-color: #d6e8fa;
  border-radius: 15px;
  padding: 20px;
}

.data-box-white {
  background-color: #ffffff;
  border-radius: 15px;
  padding: 20px;
  box-shadow: 0 5px 5px 0 rgba(139, 179, 248, 0.1);
}

.main-button {
  background-color: #0024c3;
  color: #FFF;
  padding: 10px 20px;
  border-radius: 10px;
}

.main-button:hover {
  background-color: #092188;
  color: #FFF;
  transition: 0.5s;
}

.secondary-button {
  background-color: #767676;
  color: #FFF;
  padding: 10px 20px;
  border-radius: 10px;
}

.secondary-button:hover {
  background-color: #8f8f8f;
  color: #FFF;
  transition: 0.5s;
}

.action-button {
  background-color: #034ed1;
  color: #FFF;
  padding: 6px 20px;
  border-radius: 6px;
}

.action-button:hover {
  background-color: #092188;
  color: #FFF;
  transition: 0.5s;
}

.logout-button {
  background-color: #c51212;
  color: #FFF;
  padding: 10px 20px;
  border-radius: 10px;
}

.logout-button:hover {
  background-color: #a20000;
  color: #FFF;
  transition: 0.5s;
}

.balance-text {
  font-size: 28px;
  font-weight: 700;
}

.pnl-text {
  font-size: 16px;
  font-weight: 700;
}

.price-text {
  font-size: 24px;
  font-weight: 700;
}





.header {
  padding: 15px 20px;
  background-color: #034ed1;
  border-bottom-left-radius: 20px;
  border-bottom-right-radius: 20px;
  padding-bottom: 100px;
}
@media (max-width: 399px) {
  .header {
    padding: 10px;
    border-bottom-left-radius: 20px;
    border-bottom-right-radius: 20px;
    padding-bottom: 80px;
  }
}

.header-profile {
  width: 35px;
  height: 35px;
  border-radius: 50%;
  background-color: green;
}
.header-text {
  font-size: 16px;
  font-weight: 700;
}


.negative-margin {
  margin-top: -100px;
  margin-bottom: 80px;
}
@media (max-width: 399px) {
  .negative-margin {
    margin-top: -80px;
  }
}




.menu-icon {
  color: #034ed1;
  font-size: 28px;
}




.promo-cover-img {
  width: 100%;
  border-radius: 10px;
}





.order-text {
  font-size: 14px;
}



.bonus-text {
  font-size: 16px;
  font-weight: 700;
}






/* Assets Page */
.token-icon {
  width: 45px;
  border-radius: 50%;
}
@media (max-width: 767px) {
  .token-icon {
    width: 35px;
  }
}




/* Invest Page */
.invest-modal {
  background-color: #000000ed;
  border-radius: 10px;
}

.table-container {
  background-color: #000000aa;
  border-radius: 10px;
  padding: 15px;
}

.details-table {
  overflow-x: auto;
}

.borderless th, .borderless td {
  border: none;
}






/* Membership */
.silver-vip {color: #e0e0e0;}
.silver-card {
  height: 200px;
  background: rgb(67,77,91);
  background: linear-gradient(180deg, rgba(67,77,91,1) 0%, rgba(200,195,195,1) 100%);
}

.gold-vip {color: #fff0b5;}
.gold-card {
  height: 200px;
  background: rgb(195,169,86);
  background: linear-gradient(180deg, rgba(195,169,86,1) 0%, rgba(143,136,122,1) 100%);
}

.black-vip {color: #565656;}
.black-card {
  height: 200px;
  background: rgb(32,32,32);
  background: linear-gradient(180deg, rgba(32,32,32,1) 50%, rgba(61,61,61,1) 100%);
}




/* Merchant */
.merchant-cover-container {
  background-color: #ffffff;
  border-radius: 15px;
  padding: 0px;
  box-shadow: 0 5px 5px 0 rgba(139, 179, 248, 0.1);
}

.merchant-cover {
  width: 100%;
  border-radius: 15px;
}





input[type=text], input[type=text]:focus, input[type=password], input[type=password]:focus, input[type=number], input[type=number]:focus {
  width: 100%;
  padding: 8px 10px;
  font-size: 16px;
  background-color: transparent;
  border: none;
  border-bottom: 2px solid #e4e4e4;
  border-radius: 0px;
  box-sizing: border-box;
}

.divider {
  border-top: 1px solid #0024c3;
  margin-bottom: 0px;
}





