@import url('https://fonts.googleapis.com/css2?family=Noto+Sans+TC:wght@100..900&display=swap');


.yellow {color: #FDC300;}

.text-gray {color: #7b7b7b;}
.profit-green {color:#48a600;}
.error {color: #bb0000;}

.brand-primary {color:#0024c3;}
.brand-secondary {color:#afafaf;}

body {
    margin:0;
    padding:0;
    background: #f4f4f4;
}

h1, h2, h3, h4, h5, h6 {font-family: "Noto Sans TC", sans-serif; font-weight: 500;}

h6 {font-size: 16px;}
@media (max-width: 575px) {
    h6 {font-size: 14px;}
}

p {font-size: 16px;}
@media (max-width: 575px) {
    p {font-size: 14px;}
}

a {
    text-decoration: none !important;
}

.form-bg {
    background-color: #fff;
    box-shadow: 0 5px 5px 0 rgba(139, 179, 248, 0.1);
}

.small-font {
    font-size: 14px;
}


.fluid-padding {
    padding-left: 25px;
    padding-right: 25px;
}
@media (min-width: 1200px) {
    .fluid-padding {
        padding-left: 50px;
        padding-right: 50px;
    }
}
@media (min-width: 1400px) {
    .fluid-padding {
        padding-left: 200px;
        padding-right: 200px;
    }
}
@media (min-width: 1700px) {
    .fluid-padding {
        padding-left: 320px;
        padding-right: 320px;
    }
}



button:focus {
    box-shadow: none !important;
}


.desktop-sm {
    display: block;
}
@media (max-width: 575px) {
    .desktop-sm {
        display: none;
    }
}
.mobile-sm {
    display: none;
}
@media (max-width: 575px) {
    .mobile-sm {
        display: block;
    }
}
.desktop-md {
    display: block;
}
@media (max-width: 767px) {
    .desktop-md {
        display: none;
    }
}
.mobile-md {
    display: none;
}
@media (max-width: 767px) {
    .mobile-md {
        display: block;
    }
}
.desktop-lg {
    display: block;
}
@media (max-width: 991px) {
    .desktop-lg {
        display: none;
    }
}
.mobile-lg {
    display: none;
}
@media (max-width: 991px) {
    .mobile-lg {
        display: block;
    }
}
